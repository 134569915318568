*{
  margin:0;
}

html, body, #root, .App {
  height: 100%;
  font-family: 'Poppins', sans-serif !important;
  line-height: 1.4 !important;
  font-size: 0.875rem !important;
}

.App{
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

button, select {
  cursor: pointer;
}

hr {
  margin: 0.25rem 0 !important;
}

p {
  margin-bottom: 0.5rem !important;
}

button, input, select {
  font-family : inherit !important;
}

button, select {
  cursor: pointer !important;
  user-select: none;
}

/* Start Bootstrap Mods */

.modal, .modal-backdrop {
  z-index: 3 !important;
}

.btn, .btn-primary {
  border: none !important;
}

.btn:focus, .btn-primary:focus, .form-control {
  outline: none !important;
  outline-offset: none !important;
  box-shadow: none !important;
  border: none !important;
}

h5, h6 { 
  margin: 0 !important
}

.accordion-button {
  padding: 0.5rem !important;
  font-size: inherit !important;
}

.accordion-button::after {
  color: red !important
}

/* End Bootstrap Mods */

/* Start Bootstrap like */

.cursor-pointer{
  cursor: pointer;
}

.fs-7{
  font-size: 0.85rem !important;
}

.fs-8{
  font-size: 0.70rem !important;
}

.fs-inherit{
  font-size: inherit !important;
}

.flex-1{
  flex: 1 !important;
}

.flex-1-5{
  flex: 1.5 !important;
}

.flex-2{
  flex: 2 !important;
}

.flex-2-5{
  flex: 2.5 !important;
}

.flex-3{
  flex: 3 !important;
}

.flex-4{
  flex: 4 !important;
}

.zIndex-1{
  z-index: 1 !important;
}

.zIndex-2{
  z-index: 2 !important;
}

.zIndex-3{
  z-index: 3 !important;
}

.maxh-45vh{
  max-height: 45vh !important;
}

.overflow-y-scroll{
  overflow-y: scroll !important;
}

.overflow-x-hidden{
  overflow-x: hidden !important;
}

.overflow-y-hidden{
  overflow-y: hidden !important;
}

.color-inherit{
  color: inherit !important;
}

.color-white{
  color: white !important;
}

.color-blue{
  color: blue !important
}

.color-darklb{
  color: #4a86da !important;
}

.text-blue{
  color: #2375e7;
  transition: 0.5s;
}

.text-blue:hover{
  color: blue;
}

.bg-aliceblue {
  background-color: aliceblue !important;
}

.bg-purple {
  background-color: #c700ff !important;
}

.bg-darkerblue {
  background-color: #001d47 !important;
}

.bg-orange {
  background-color: #ffc769 !important;
}

.bg-yellow {
  background-color: #feff49 !important;
}

.bg-gw {
  background-color: ghostwhite !important;
}

.bg-gw-lb {
  background-color: ghostwhite;
  transition: 0.7s;
}
.bg-gw-lb:hover {
  background-color: #bdd5ff;
}

.be-black-light {
  border-right: 1px solid #d8d8d882;
}

.icon-color-lb{
  color: #477edd;
  transition: 0.7s;
}

.icon-color-lb:hover, .bg-lb-white:active{
  color: #2842da !important;
}

.bg-lb-white{
  background-color: #477edd !important;
  color: white !important;
  transition: 0.7s !important;
}

.bg-lb-white:hover, .bg-lb-white:active{
  background-color: #2842da !important;
}

.bg-lb-white-selected{
  background-color: #2842da !important;
  color: white !important;
}

.lb-white{
  color: white !important;
  background: linear-gradient(315deg, var(--lb-white) 0%, var(--lb-white-reverse) 64%) !important;
  transition: --lb-white 0.7s, --lb-white-reverse 0.7s !important;
}

.lb-white:hover, .lb-white:active{
  --lb-white: #477edd !important;
  --lb-white-reverse: #2842da !important;
}

@property --lb-white {
  syntax: '<color>';
  initial-value: #2842da;
  inherits: false;
}

@property --lb-white-reverse {
  syntax: '<color>';
  initial-value: #477edd;
  inherits: false;
}

.silver-black{
  color: white !important;
  background: linear-gradient(315deg, var(--silver-black) 0%, var(--silver-black-reverse) 64%) !important;
  transition: --silver-black 0.7s, --silver-black-reverse 0.7s !important;
}

.silver-black:hover, .silver-black:active{
  --silver-black: #6c6b6b !important;
  --silver-black-reverse: #353535 !important;
}

@property --silver-black {
  syntax: '<color>';
  initial-value: #353535;
  inherits: false;
}

@property --silver-black-reverse {
  syntax: '<color>';
  initial-value: #6c6b6b;
  inherits: false;
}

.ellipsis-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

/* End Bootstrap like */

/* Start Generic Styles */

.rounded-button{
  border-style: none;
  border-radius: 10px !important;
  text-align: center;
  cursor: pointer;
  outline: none;
  transition: background 0.7s;
  user-select: none;
}

.round-button{
  padding: 7.5px 10px;
  border: none;
  border-radius: 7px;
}

.outer-container{
  display: flex;
  flex-direction: column;
  /* height: 100%; */
  flex: 1;
  background: ghostwhite;
  overflow: auto;
}

.grid-container-two{
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: auto auto;
}

.grid-autofit {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
}

.alert-outer-container{
  background-color: rgb(21, 21, 21);
  color: white;
  padding: 10px;
  text-transform: uppercase;
  border-radius: 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.03) 0px 2px 2px 2px;
  min-width: 300px;
  max-width: 80vw;
  width: max-content;
  box-sizing: border-box;
  margin: 10px;
}

.alert-inner-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
  padding-left: 5px;
  padding-right: 5px;
  max-height: 30vh;
}

.alert-icon-style{
  vertical-align: middle;
  margin-right: 7.5px;
  min-width: 18px;
}

.alert-close-button{
  margin-left: 7.5px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  color: rgb(255, 255, 255);
}

.icon-style{
  width: inherit;
  height: inherit;
  vertical-align: middle;
  transition: 0.7s;
}

.icon-container-blocked{
  opacity: 0.3;
  cursor: default;
  pointer-events: none;
}

/* End Generic Styles */

/* Start Header, NavBar */

.header-bs{
  box-shadow: 0px 0px 0px 5px rgba(0, 0, 0, 0.075);
  /* box-shadow: 1px -2px 8px 2px rgba(0,0,0,0.25), 0 2px 1px 1px rgba(255,255,255,0.9), 0 -2px 1px rgba(0,0,0,0.25); */ /* Darker */
}

/* End Header, NavBar */

/* Start Home Component */

.home-links-container{
  width: 135px !important;
  height: 135px;
  max-width: 135px !important;
  max-height: 135px;
  /* max-width: 135px !important;
  max-height: 135px !important; */
  border-radius: 7px;
}

/* End Home Component */

/* Start Login Component */

.login-container{
  width: 350px;
}

/* End Login Component */

.table-inner-container{
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
  margin-left: 1%;
  margin-right: 1%;
}

.rt-bg{
  background-color: #e5f0ff !important;
  background-image: linear-gradient(315deg, #e1eefb 0%, #f7faff 44%, #fafcff 74%) !important;
  background-attachment: fixed !important;
}

.rt-header{
  background-color: #dfebff !important;
  color: #2460cf !important;
}

.rt-header-highlight:hover {
  background-color: #d4e4ff !important;
  transition: background 0.75s !important;
}

.rt-row-highlight:hover {
  background-color: #e2edff !important;
  transition: background 0.5s !important;
}

.rt-row-even { 
  background: #e3e8f380;
}

.rt-expanded-row-highlight:hover {
  background-color: #e2edff !important;
  transition: background 0.5s !important;
}

.rt-expanded-row-odd { 
  background: #e0ffda21;
}

.rt-expanded-row-even { 
  background: #bdd8bd27;
}

.rt-cell-border-end {
  border-right: 1px solid rgb(0, 0, 0, 0.15) !important;
}

.rt-cell-border-end:last-child {
  border-right: 0 !important;
}

/* End Table CSS */

/* Start Modal CSS */

.modal-outer-container{
  position: fixed;
  z-index: 2;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  /* overflow: auto; */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.7); /* Black w/ opacity */
}

.modal-inner-container-large{
  display: flex;
  flex-direction: column;
  background-color: ghostwhite;
  margin: 30px auto auto auto;
  padding: 20px;
  max-height: 90%;
  border-radius: 7px;
}

.modal-inner-container{
  display: flex;
  flex-direction: column;
  background-color: ghostwhite;
  margin: 30px auto auto auto;
  padding: 20px;
  max-width: 450px;
  max-height: 85%;
  border-radius: 7px;
}

.modal-inner-container-small{
  display: flex;
  flex-direction: column;
  background-color: ghostwhite;
  margin: 30px auto auto auto;
  padding: 20px;
  max-width: 350px;
  max-height: 85%;
  border-radius: 7px;
}

.modal-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
}

/* WE ARE HERE CLEANING UP */
.modal-header-section-title-container{
  padding-top: 7.5px;
  padding-bottom: 5px;
  flex: 1;
  cursor: pointer;
  text-align: center;
}
/* flex-1 cursor-pointer text-center py-2 */

.modal-header-section-title{
  display: flex;
  justify-content: center;
  color: white;
}
/* d-flex justify-content-center text-white */

.modal-close-button {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  padding: 1rem;
  border-radius: 50px;
  width: 20px;
  height: 20px;
}

.modal-form-button{
  padding: 5px 10px;
  border: none;
  border-radius: 7px;
}

.modal-form-container{
  display: flex;
  flex-direction: column;
  /* border: 1px solid; */
  margin-top: 2.5px;
  margin-bottom: 2.5px;
  /* background-color: aliceblue; */
  overflow: auto;
}

/* Check this and delete */
.modal-form-input-container{
  padding: 10px;
  justify-content: space-between;
  align-items: center;
  /* border-bottom: 1px solid darkblue; */
}

/* .modal-form-input-container:last-of-type{
  border-bottom: none;
} */

/* Check this and delete */
.modal-form-input-title{
  display: flex;
  align-items: center;
  flex: 1;
}

.modal-form-row-container{
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.modal-form-row-left-container{
  display: flex;
  flex: 1.5;
  align-items: center;
  justify-content: flex-end;
  margin-right: 5px;
  min-width: 100px;
}

.modal-form-row-right-container{
  display: flex;
  flex: 2;
  min-width: 150px;
}

.modal-form-input{
  /* flex: 1.3; */
  /* flex: 2; */
  flex: 1.5;
  min-width: 100px;
  padding-left: 2.5px;
  width: 90%;
}

.modal-form-checkbox-container{
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.modal-form-checkbox{
  margin-right: 5px;
}

.modal-form-input-flex{
  width: 100%;
}

.modal-form-small-input{
  margin-right: 5px;
  width: 80px;
}

.modal-table-outer-container{
  display: flex;
  flex-direction: column;
  border: 1px solid;
  background-color: aliceblue;
  overflow: auto;
}

.modal-table-row-inner-container{
  display: grid;
  overflow: auto;
}

.modal-table-sticky-header-container{
  display: flex;
  position: sticky;
  top: 0;
  padding: 5px;
  border-bottom: 1px solid darkblue;
  /* width: 100%; */
  background: white;
}

.modal-table-sticky-header{
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
}

.modal-table-sticky-col-header{
  display: flex;
  position: sticky;
  left: 0;
  height: 100%;
  background: floralwhite;
}

.modal-table-sticky-footer-container{
  display: flex;
  width: 100%;
  position: sticky;
  bottom: 0;
  align-items: center;
  justify-content: center;
  padding-top: 5px;
  background-color: ghostwhite;
}

.modal-table-row-input-container{
  display: flex;
  padding: 5px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid darkblue;
}

.modal-table-row-inner-container .modal-table-row-input-container:hover, .hover-bg-light-red:hover{
  background-color: #FFF0F5C0;
}

.modal-table-loading-text{
  display: flex;
  flex: 1;
  align-self: center;
  justify-content: center;
  margin: 15px;
}

.modal-table-input{
  padding-left: 1px;
  width: 100%;
}

.modal-table-title{
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  text-align: center;
  overflow-wrap: anywhere;
}

.modal-table-row-container{
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  text-align: center;
  /* min-width: 100px; */
  min-width: 125px;
  margin-left: 2.5px;
  margin-right: 2.5px;
}

.modal-table-col-container{
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  text-align: center;
  min-height: 50px;
}

.modal-table-icon-container{
  display: flex;
  padding: 5px;
  border-radius: 50px;
  cursor: pointer;
}

  /* Start Loading Modal CSS */

.loading-modal-outer-container{
  z-index: 1000;
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.7); /* Black w/ opacity */
}

.loading-modal-container{
  top: 10%;
}

.modal-loading-blocked{
  opacity: 0.85;
  cursor: default;
  pointer-events: none;
}

  /* End Loading Modal CSS */

  /* Start Alert Modal CSS */

.alert-modal-container{
  position: absolute;
  top: 80px;
  right: 50%;
  z-index: 3;
  padding: 15px;
}

  /* End Alert Modal CSS */

  /* Start Confirm Modal CSS */

.react-confirm-alert {
  display: flex;
  flex: 1;
  /* overflow: auto; */
}

.modal-large-confirm-container{
  min-width: 300px;
  max-width: 90%;
  margin: auto;
  padding: 25px;
  /* text-align: left; */
  background: #fff;
  border-radius: 7px;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.13);
  color: #666;
}

.modal-delete-confirm-container{
  min-width: 250px;
  max-width: 80%;
  margin: auto;
  padding: 25px;
  text-align: left;
  background: #fff;
  border-radius: 7px;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.13);
  color: #666;
}

.modal-delete-confirm-buttons-container{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.modal-confirm-button{
  flex: 1;
  min-width: 100px;
}

/* End Confirm Modal CSS */

/* Start PDF Display CSS */

.modal-pdf-outer-container{
  display: flex;
  flex-direction: column;
  border: 1px solid;
  background-color: aliceblue;
  overflow: auto;
}

.pdf-sticky-overlay{
  display: flex;
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  left: 0;
  user-select: none;
  transition: background 0.7s;
}

.pdf-sticky-overlay:hover{
  background: aliceblue;
}

.pdf-doc-container{
  min-width: 600px;
}

.react-pdf__Page__svg{
  margin: auto;
}

.textLayer {  
  inset: unset !important;
}

.annotationLayer {  
  left: unset !important;
}

/* End PDF Display CSS */

/* Start Signs */
.signs-standalone-container {
  max-width: 400px;
}
/* End Signs */

/* Start Validation */
.validation-logo {
  max-height: 85px;
  object-fit: scale-down;
}

.validation-text-container {
  width: 100%;
}
/* End Validation */

/* End Modal CSS */

@media only screen and (min-width: 768px) {

  html, body, #root, .App {
    font-size: 0.925rem !important;
  }

  .flex-md-1 {
    flex: 1 !important;
  }

  .grid-container-two{
    grid-auto-flow: column;
    grid-template-rows: auto auto;
    grid-column-gap: 50px;
  }

  .login-container{
    width: 450px;
  }

  .home-links-container{
    width: 165px !important;
    height: 165px;
    max-width: 165px !important;
    max-height: 165px;
  }

  .alert-outer-container{
    padding: 15px;
    min-width: 400px;
    max-width: 75vw;
  }

  .table-inner-container{
    width: 97.5%;
    margin-left: auto;
    margin-right: auto;
  }

  .modal-inner-container-large{
    max-width: 80%;
  }

  .modal-inner-container{
    max-width: 715px;
  }

  .modal-form-row-left-container{
    flex: 1.5;
  }

  .modal-form-row-right-container{
    flex: 2;
  }

  .modal-form-input{
    width: initial;
  }

  .modal-form-input-flex{
    width: unset;
  }

  .modal-form-input-container{
    display: flex;
  }

  .modal-form-input-title{
    margin-right: 5px;
  }

  .modal-table-row-input-container, .modal-table-sticky-header-container{
    padding: 10px;
  }

  .modal-delete-confirm-container{
    min-width: 350px;
    max-width: 550px;
    padding: 30px;
  }

  .modal-large-confirm-container {
    min-width: 400px;
    max-width: 80%;
    padding: 30px;
  }

  .w-md-75 {
    width: 75% !important;
  }

  .signs-standalone-container {
    max-width: 50%;
  }

  .validation-logo {
    max-height: 125px;
  }
  
  .validation-text-container {
    width: 75%;
  }

  .validation-doc-info-container {
    min-width: 350px;
    max-width: 450px
  }
}


@media only screen and (min-height: 600px) {
  .table-container{
    height: 90%;
  }
}
